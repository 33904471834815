.sidebar_header {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0;
}

.ant-layout-sider {
    background-color: var(--main-color) !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: var(--main-color) !important;
}

.ant-menu.ant-menu-dark {
    height: 100% !important;
}

.ant-layout-sider-trigger {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    background-color: var(--main-color) !important;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    color: white !important;
}

.ant-menu-submenu {
    transition: .05s;
    border-radius: 2px;
    border: 2px solid transparent !important;
}

.ant-menu-submenu .ant-menu-submenu-title svg {
    margin-left: -6px !important;
}

.ant-menu-item:hover,
.ant-menu-submenu:hover {
    border: 2px solid var(--main-hover) !important;

}

.ant-menu-submenu-open {
    border: 2px solid var(--main-hover) !important;
}

.ant-menu-vertical .ant-menu-item svg {
    margin-top: 8.5px;
    margin-left: -7px;
}

.ant-menu-vertical .ant-menu-item {
    padding-top: 8px !important;
}

.ant-menu-vertical .ant-menu-item .ant-menu-title-content {
    display: none;
}

.ant-menu-inline .ant-menu-item {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu.ant-menu-dark .ant-menu-sub {
    color: white !important;
    border-radius: 2px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--main-hover) !important;
    border-radius: 2px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .not-selected {
    background-color: transparent !important;
    border-radius: 2px;
}

.ant-menu-inline {
    width: 90% !important;
    margin: 0 auto !important;
}

.site-layout-background {
    background-color: white;
}

/* Swiper */

.cancel_link {
    color: red;
    position: absolute;
    top: -10px;
    right: 3px;
    cursor: pointer;
    font-size: 1.7em;
}

.active_link {
    border-bottom: 2px solid var(--main-color);
    background-color: #fff;
}

.active_link a {
    color: #111 !important;
    font-weight: bold;
}

.link:hover {
    border-color: #fff;
}

.link:hover a {
    color: #fff;
}

.link a {
    color: #ddd;
}

.link {
    border: 1px solid #fff;
    border-bottom: 0;
    margin: 0 !important;
    margin-left: -6px !important;
    position: relative;
    padding: 6px 26px !important;
}

.link_bar {
    display: flex;
    grid-gap: 10px;
    height: 35px;
    overflow: auto;
    white-space: nowrap;
}

.link_bar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.link_bar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.ant-menu-item {
    padding-left: 18px !important;
    border-radius: 2px;
    user-select: none;
    margin-left: 9px !important;
    margin-right: 13px !important;
    border: 2px solid transparent !important;
    /*transition: .05s;*/
}

.ant-menu-title-content {
    user-select: none;
}

.ant-menu-submenu .ant-menu-item {
    padding-top: 0 !important;
}

.ant-menu-item {
    padding-left: 20px !important;
    /*padding-top: 8px !important;*/
}

/* qoshimcha */
.ant-menu-root {
    overflow: auto !important;
    height: 420px !important;
}

.ant-layout-sider-children {
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    position: sticky;
    top: 0;
}

.ant-menu-item {
    display: flex;
    grid-gap: 12px;
}

.ant-menu-submenu-title {
    padding-left: 26px !important;
}

.ant-menu-submenu-title .ant-menu-title-content {
    padding: 0 0 0 10px !important;
}

.ant-layout-sider-collapsed .ant-menu-submenu {
    margin: 0 10px;
    text-align: center !important;
}

.ant-menu-submenu-title {
    margin: -3px 0 0 0 !important;
}

.ant-layout-sider-collapsed .ant-menu-submenu-title {
    margin: 0 0 0 0 !important;
    padding: 0 24px !important;
}

/*.ant-layout-sider-collapsed .ant-menu-submenu-title .ant-menu-title-content {*/
/*    visibility: hidden;*/
/*}*/

.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item .ant-menu-title-content {
    display: block !important;
}

.sidebar_header {
    padding: 20px 3px;
}

.site-layout {
}

.site-main-pages {
    height: calc(100% - 35px);
}