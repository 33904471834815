.sklad-head-cap {
  display: flex;
  margin-top: -28px;
  column-gap: 20px;

  .sklad-head-cap__item {
    display: flex;
    column-gap: 20px;

    .document-id-input {
      width: 105px;
    }
  }

  .sklad-head-body__comment {
    width: 229px;
    resize: none;
  }

  .cap-text {
    padding: 0 5px 0 0;
  }
}

.sklad-head-body {
  display: flex;
  column-gap: 20px;
  padding: 17px 0 8px 0;

  .sklad-head-body__item {
    display: flex;
    column-gap: 25px;
    align-items: center;
    //border: 1px solid red;
    & > div {
      display: flex;
    }

    .head-select {
      min-width: 190px;
    }
  }

  .cap-text {
    padding: 0 5px 0 0;
  }

}

.sklad-select,
.model-select,
.mebel-select {
  min-width: 180px !important;
}