.second-actions {
  display: flex;
}

.action_btn {
  color: #fff !important;
  display: flex !important;
  justify-content: center;
  padding: 10px !important;
  margin-right: 9px;
  align-items: center;
  border: none !important;
  border-radius: var(--input-radius) !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.main-btn {
  background-color: var(--main-color) !important;
}

.edit-btn {
  background-color: orange !important;
}

.delete-btn {
  background-color: #DC200E !important;
}

.action_icon {
  font-size: 20px;
  margin-right: 3px;
}

@media only screen and (max-width: 500px) {
  .user_avatar {
    display: none !important;
  }

  .action_text {
    display: none !important;
  }

  .site-layout-background {
    padding: 10px !important;
  }
}