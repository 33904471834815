.service_item {
    color: white;
    display: flex;
    background-color: var(--main-color);
    border-radius: 5px;
    padding: 10px 20px;
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
}

.service_item:hover {
    box-shadow: 0 2px 10px rgba(14, 22, 33, .5);
}

.service_grid {
    display: grid;
    margin-top: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px 10%;
}