
.ant-input-affix-wrapper {
    border: none;
    border-radius: var(--input-radius);
    height: 35px;
    padding: 0 15px;
    margin-left: -18px;
    background-color: #fff !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    box-shadow: none;
}