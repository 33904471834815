/*@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");*/
/*@import url('https://fonts.googleapis.com/css2?family=Raleway:ital@0;1&family=Work+Sans:wght@100;400&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Raleway:ital@0;1&family=Work+Sans:wght@100;400&display=swap');

* {
    font-family: "Comfortaa", sans-serif;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --main-color: #0E1621;
    --main-hover: rgba(44, 52, 63, .7);
    --input-bg: #f2f2f2;
    --input-radius: 2px;
    --label-color: #828282;
    --table-border: #535356;
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #0E1621;
    background: rgba(54, 62, 73, .7);
    border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 3px;
}

*::-webkit-scrollbar-thumb:hover {
    background: #eee;
}

.cirle {
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
}

/*
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(153, 186, 221);
  border-radius: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.leaflet-container {
    width: 100%;
    height: 400px;
}

body {
    -webkit-print-color-adjust: exact;
}
